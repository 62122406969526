<template>
  <b-container fluid="xs">
    <vue-element-loading :active="isloading" spinner="ring" background-color="rgba(255,255,255,0.6)" size="400" color="#F00"/>
    <div v-show="items.length">
      <b-table-simple hover small caption-top responsive>
        <b-thead head-variant="light">
          <b-tr>
            <b-th>
              {{$t('message.usr_th0')}}
            </b-th>
            <b-th>
              {{$t('message.usr_th1')}}
            </b-th>
            <b-th>
              <b-icon icon="gem" class="d-none d-sm-inline-block"></b-icon>{{$t('message.usr_th2')}}
            </b-th>
            <b-th>
              {{$t('message.usr_th3')}}
            </b-th>
            <b-th>
              <b-icon icon="clock" class="d-none d-sm-inline-block"></b-icon>{{$t('message.usr_th4')}}
            </b-th>
            <b-th>
              <b-icon icon="clock" class="d-none d-sm-inline-block"></b-icon>{{$t('message.usr_th5')}}
            </b-th>
            <b-th>
              <b-icon icon="wifi"></b-icon>{{$t('message.usr_th6')}}
            </b-th>
            <b-th>
              <b-icon icon="lightning-fill" class="d-none d-sm-inline-block"></b-icon>{{$t('message.usr_th7')}}
            </b-th>
            <b-th>
              {{$t('message.usr_th8')}}
            </b-th>
            <b-th>
              {{$t('message.usr_th9')}}
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody v-for="(item, index) in items" :key="index">
          <b-tr>
            <b-td><span>{{item.id}}</span>
            <b-icon :icon="utype==1?'person-x-fill':'person-x'" class="ml-1 d-none d-lg-inline-block" @click="makeblack(item)"></b-icon>
            </b-td>
            <b-td>{{item.nickname}}
            <b-button size="sm" variant="outline-info" :to="{path:'admlogs',query:{uid:item.id,tm:new Date().getTime()}}">
            <b-icon icon="clock-history"></b-icon>
            </b-button>
            </b-td>
            <b-td>{{item.balance}}</b-td>
            <b-td>{{item.balancx}}</b-td>
            <b-td>{{item.regtime}}</b-td>
            <b-td>{{item.lastactive}}</b-td>
            <b-td>{{item.ipaddress}}</b-td>
            <b-td>
              <b-button-group size="sm">
                <b-button :variant="item.chargetms0 | toVar1" :disabled="item.chargetms0==0"
                :to="{path:'admbill0',query:{uid:item.id,tm:new Date().getTime()}}">{{item.chargetms0}}</b-button>
                <b-button :variant="item.chargetms1 | toVar1" :disabled="item.chargetms1==0"
                :to="{path:'admbill1',query:{uid:item.id,tm:new Date().getTime()}}">{{item.chargetms1}}</b-button>
                <b-button :variant="item.chargetms2 | toVar1" :disabled="item.chargetms2==0"
                :to="{path:'admbill2',query:{uid:item.id,tm:new Date().getTime()}}">{{item.chargetms2}}</b-button>
              </b-button-group>
            </b-td>
            <b-td>
              <b-button size="sm" :variant="item.prepaytms | toVar1" :disabled="item.prepaytms==0" :to="{path:'admprepays',query:{uid:item.id,tm:new Date().getTime()}}">{{item.prepaytms}}</b-button>
            </b-td>
            <b-td v-if="workinglo==index">
                  <b-input-group size="sm">
                    <b-form-input v-model="item.chofflowi"></b-form-input>
                    <b-input-group-append>
                      <b-button variant="info" @click="saveofflow(item)">{{$t('message.btn_save')}}</b-button>
                    </b-input-group-append>
                  </b-input-group>
            </b-td>
            <b-td v-else>
              <b-button size="sm" variant="info" @click="setlow(index)">{{item.chofflowi}}</b-button>
              <b-button class="ml-2" v-if="utype<3" size="sm" variant="outline-info" :to="{path:'admvcards',query:{proxy:item.id,tm:new Date().getTime(),create:'1'}}">
              <b-icon icon="credit-card"></b-icon>
              </b-button>
              <b-button class="ml-2" v-if="utype==2" size="sm" variant="outline-info" :to="{path:'admvcards',query:{proxy:item.id,tm:new Date().getTime()}}">
              <b-icon icon="credit-card-fill"></b-icon>
              </b-button>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
    <div v-show="items.length==0" class="pl-5 pr-5">
      <b-card class="xnshadow" header-tag="header" footer-tag="footer">
        <template v-slot:header>
          <h4 class="mb-0">
            <b-icon icon="people"></b-icon> Users Management
          </h4>
        </template>
        <b-card-text>
          <p>
            No User Found<br />
          </p>
        </b-card-text>
      </b-card>
    </div>
    <b-form inline class="mt-4 ml-4" @submit.stop.prevent>
      <b-button-group class="mr-4 mb-4">
        <b-button variant="success" @click="fetchprev" :disabled="pageid==0">{{$t('message.btn_prevpage')}}</b-button>
        <b-button v-if="pageid>0" variant="outline-success" :disabled="true" >{{pageid+1}}</b-button>
        <b-button variant="success" @click="fetchNext" :disabled="pageid==newpagestart.length">{{$t('message.btn_nextpage')}}</b-button>
      </b-button-group>
      <b-radio-group buttons v-model="utype" class="mr-4 mb-4" :options="utypes" @change="uchange" button-variant="outline-primary"></b-radio-group>
      <b-input-group class="mb-4">
        <b-form-input placeholder="nickname" v-model="searchstr" @keyup.enter="search"></b-form-input>
        <b-input-group-append>
          <b-button variant="info" @click="search">{{$t('message.btn_search')}}</b-button>
        </b-input-group-append>
      </b-input-group>
    </b-form>
    <b-modal v-model="modalshow" no-close-on-backdrop no-close-on-esc hide-header ok-only :ok-title="$t('message.btn_ok')">{{modalmsg}}</b-modal>
    <b-alert class="ml-4 mr-4" :show="dismissCountDown" dismissible variant="warning" @dismissed="dismissCountDown=0" @dismiss-count-down="countDownChanged">
    {{alertmsg}}
    <b-progress variant="warning" :max="dismissSecs" :value="dismissCountDown" height="4px"></b-progress>
    </b-alert>
  </b-container>
</template>

<script>
  export default {
    name: 'user',
    mounted() {
      if (this.$route.query.alertmsg) {
        this.dismissSecs = 10;
        this.dismissCountDown = 10;
        this.alertmsg = this.$route.query.alertmsg;
        delete this.$route.query.alertmsg;
      }
      this.utype = parseInt(localStorage.getItem('ustype')) || 0;
      this.fetchData();
    },
    data() {
      return {
        isloading: false,
        modalshow: false,
        modalmsg: '',
        alertmsg: '',
        utype: 1,
        workinglo: -1,
        dismissSecs: 0,
        dismissCountDown: 0,
        searchstr: '',
        utypes: [{
          text: this.$t('message.btn_ugst'),
          value: 0
        }, {
          text: this.$t('message.btn_uvip'),
          value: 1
        }, {
          text: this.$t('message.btn_prox'),
          value: 2
        }, {
          text: this.$t('message.btn_umgr'),
          value: 3
        }, {
          text: this.$t('message.btn_ublk'),
          value: 4
        }],
        maskenabled: false,
        items: [],
        pageid:0,
        newpagestart:[]
      };
    },
    methods: {
      countDownChanged(dismissCountDown) {
        this.dismissCountDown = dismissCountDown
      },
      search() {
        delete this.$route.query.search;
        this.pageid = 0;
        this.newpagestart = [];
        this.fetchData();
      },
      uchange($event) {
        this.searchstr = '';
        this.utype = $event;
        localStorage.setItem('ustype',''+$event);
        this.pageid = 0;
        this.newpagestart = [];
        this.fetchData();
      },
      fetchprev() {
        this.pageid = this.pageid-1;
        this.fetchData();
      },
      fetchPage(page) {
        this.pageid = page;
        this.fetchData();
      },
      fetchNext() {
        this.pageid = this.pageid+1;
        this.fetchData();
      },
      async fetchData() {
        let w = window.innerWidth;
        let h = window.innerHeight;
        this.isloading = true;
        let lotoken = localStorage.getItem('token');
        let params = 'token=' + lotoken + '&utype=' + this.utype + '&pagesize=' + this.$pagesize+'&w='+w+'&h='+h;
        if (this.$route.query.search) {
          params = params + '&search='+this.$route.query.search;
        } else {
          if (this.searchstr) {
            params = params + '&search=' + this.searchstr;
          }
        }
        if (this.pageid>0 && this.newpagestart[this.pageid-1]) {
          params = params + '&last='+encodeURIComponent(JSON.stringify(this.newpagestart[this.pageid-1]));
        }
        let axresp = await this.axios.post('/admusers?tm=' + new Date().getTime(), params);
        this.isloading = false;
        if (axresp.status == 200) {
          let axdata = axresp.data;
          this.items = axdata.Items;
          if (axdata.LastEvaluatedKey) {
            if (this.newpagestart.length<=this.pageid) {
              this.newpagestart.push(axdata.LastEvaluatedKey);
            } else {
              this.newpagestart[this.pageid] = axdata.LastEvaluatedKey;
            }
          }
        } else {
          this.items = [];
        }
      },
      async makeblack(itm) {
        let lotoken = localStorage.getItem('token');
        let theapi = 'token=' + lotoken + '&id=' + itm.id;
        let axresp = await this.axios.post('/admusmkblack?tm=' + new Date().getTime(), theapi);
        if (axresp.status == 200) {
          this.pageid = 0;
          this.newpagestart = [];
          this.fetchData();
        }
      },
      closemask() {
        this.maskenabled = false;
      },
      setlow(idx) {
        this.workinglo = idx;
      },
      async saveofflow(itm) {
        let numchofflowi = Number(itm.chofflowi);
        if (isNaN(numchofflowi)) {
            this.modalmsg = 'Fill number (0,0.02~0.4)';
            this.modalshow = true;
        } else if (numchofflowi>0 && numchofflowi<0.02) {
            this.modalmsg = 'can not more than 0.08A';
            this.modalshow = true;
        } else if (numchofflowi>0.4) {
            this.modalmsg = 'can not more than 0.4A';
            this.modalshow = true;
        } else {
          this.workinglo = -1;
          let token = localStorage.getItem('token');
          let theapi = 'cmd=1&token=' + token + '&id=' + itm.id + '&chofflowi=' + itm.chofflowi;
          let axresp = await this.axios.post('/admusrop?tm=' + new Date().getTime(), theapi);
          if (axresp.status == 200) {
            this.fetchData();
          }
        }
      },
    }
  }
</script>
